



























































































































































































































import { EnumValidation } from '@/app/infrastructures/misc/Constants/cancelValidation'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { Vue, Component, Watch } from 'vue-property-decorator'
import ImageViewer from '@/app/ui/components/ImageViewer/index.vue'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import EditIcon from '@/app/ui/assets/edit_icon_alt2.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import controller from '@/app/ui/controllers/CancelValidationController'
import {
  ITableCell,
  ICancelValdationDetailParameters,
  IHeaderCell,
} from '@/data/infrastructures/misc/interfaces/cancelValidation'
import {
  CancelDetail,
  CancelDetailData,
} from '@/domain/entities/CancelValidation'
import { Pagination } from '@/domain/entities/Pagination'

@Component({
  components: {
    LoadingOverlay,
    ImageViewer,
    RadioButton,
    TextInput,
    Button,
    EditIcon,
    DataTableV2,
    PaginationNav,
  },
})
export default class CancelValidationDetail extends Vue {
  controller = controller

  enumValidation = EnumValidation
  isLoading = false
  showProofPhoto = false
  indexOfProofPhoto = 0
  proofPhoto: string[] = []

  detailData: CancelDetailData = {
    bookingId: '-',
    cancelReason: '-',
    cancelLocation: '-',
    courier3lc: '-',
    courierId: '-',
    courierName: '-',
    courierPhoneNumber: '-',
    courierTypeValid: '-',
    createdAt: '-',
    description: '-',
    historyId: undefined,
    shipmentId: '-',
    isCancelValid: undefined,
    proofImage: [],
  }

  validationValue: EnumValidation = EnumValidation.empty

  fictiveReason = ''
  isAlreadyFictive = false
  isAlreadyValid = false
  isEditFictiveReason = false

  historyLogTableData: ITableCell[][] = []
  historyLogPagination = new Pagination()

  historyLogParameters: ICancelValdationDetailParameters = {
    page: 1,
    perPage: 5,
  }

  historyLogHeaders = [
    this.headerCellMapper('Diubah Oleh', '50%'),
    this.headerCellMapper('Tanggal', '50%'),
  ]

  isQUECancel = false

  created(): void {
    this.fetchPickupCancelValidation(true)
  }

  get isShowSubmitButton(): boolean {
    return (
      (this.validationValue === EnumValidation.valid && !this.isAlreadyValid) ||
      (this.validationValue === EnumValidation.fictive &&
        !this.isAlreadyFictive) ||
      (this.validationValue === EnumValidation.fictive &&
        this.isAlreadyFictive &&
        this.isEditFictiveReason)
    )
  }

  get isSubmitButtonDisabled(): boolean {
    return (
      this.validationValue === EnumValidation.fictive && !this.fictiveReason
    )
  }

  public fetchPickupCancelValidation(reset?: boolean): void {
    if (reset) {
      this.historyLogParameters.page = 1
    }

    const historyId = <string>this.$route.params.historyId

    this.controller.getCancelDetail({
      historyId: historyId,
      params: this.historyLogParameters,
    })
  }

  public onSubmitUpdateCancelValidation(): void {
    const historyId = <string>this.$route.params.historyId

    this.controller.updateStatusValidation({
      historyId: historyId,
      status: <number>(<unknown>parseInt(this.validationValue)),
      description: this.fictiveReason,
    })
  }

  public onShowProofPhoto(index: number): void {
    this.showProofPhoto = true
    this.indexOfProofPhoto = index
  }

  public onChangeIsValid(value: EnumValidation): void {
    if (value === this.enumValidation.fictive) {
      this.isEditFictiveReason = true
    } else {
      this.isEditFictiveReason = false
      this.isAlreadyFictive = false
      this.fictiveReason = ''
    }
    this.validationValue = value
  }

  private setPickupStatus(status: number): void {
    switch (status) {
      case 1:
        this.validationValue = this.enumValidation.valid
        this.isAlreadyValid = true
        this.isAlreadyFictive = false
        break

      case 2:
        this.validationValue = this.enumValidation.fictive
        this.isAlreadyValid = false
        this.isAlreadyFictive = true
        this.isEditFictiveReason = false
        break

      default:
        this.validationValue = this.enumValidation.empty
        this.isAlreadyValid = false
        this.isAlreadyFictive = false
        break
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  @Watch('controller.cancelDetailData')
  onDetailDataChange(data: CancelDetail): void {
    this.detailData = <CancelDetailData>{
      ...data.detail,
      createdAt: Utils.formatTimeZone(
        Utils.formatDateWithIDLocale(
          <string>data.detail?.createdAt,
          'DD MMM YYYY, HH:mm Z'
        )
      ),
    }

    this.isQUECancel = Boolean(
      data.detail?.cancelReason === 'ZZZZZ' &&
        !data.detail.courierId &&
        data.detail.isCancelValid === 1
    )

    this.proofPhoto = data.detail?.proofImage || []

    this.historyLogTableData = <ITableCell[][]>data.historyLog?.data?.map(
      history => {
        return [
          this.tableCellMapper(<string>history.updatedBy, '50%'),
          this.tableCellMapper(
            Utils.formatTimeZone(
              Utils.formatDateWithIDLocale(
                <string>history.updatedDate,
                'DD MMM YYYY, HH:mm Z'
              )
            ),
            '50%'
          ),
        ]
      }
    )

    this.historyLogPagination = <Pagination>data.historyLog?.pagination
    this.setPickupStatus(<number>data.detail?.isCancelValid)
    this.fictiveReason = <string>data.detail?.description
  }

  @Watch('controller.isSuccessUpdateStatus')
  onIsSuccessUpdateStatusValidationChange(value: boolean): void {
    if (value) {
      this.$notify({
        title: 'Update Validation Status Success',
        text: 'Cancel Validation Status has been updated successfully',
        type: 'success',
        duration: 3000,
      })

      this.fetchPickupCancelValidation(true)
      this.controller.setIsSuccessUpdateStatus(false)
    }
  }
}
