import { UpdateStatusValidationRequestInterface } from '../contracts/CancelValidationRequest'

export class UpdateStatusValidationRequest
  implements UpdateStatusValidationRequestInterface {
  private status: number
  private description: string

  constructor(status: number, description: string) {
    this.status = status
    this.description = description
  }

  toPayload(): { status: number; description: string } {
    return {
      status: this.status,
      description: this.description,
    }
  }
}
