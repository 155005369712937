import { Utils } from '@/app/infrastructures/misc'
import Vue from 'vue'
import {
  ICancelListParams,
  ICancelValdationDetailParameters,
} from '@/data/infrastructures/misc/interfaces/cancelValidation'
import {
  Cancel,
  CancelDetail,
  Cancels,
  CourierShipmentCancels,
  Summary,
} from '@/domain/entities/CancelValidation'
import { Pagination } from '@/domain/entities/Pagination'
import { container } from 'tsyringe'
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators'
import { CancelValidationPresenter } from '../presenters/CancelValidationPresenter'
import store from '../store'
import { UpdateStatusValidationRequest } from '@/data/payload/api/CancelValidationRequest'

export interface CancelValidationState {
  isLoading: boolean
  cancelListData: Cancel[]
  paginationData: Pagination
  cancelSummaryData: Summary
  cancelDetailData: CancelDetail
  courierShipmentCancelListData: CourierShipmentCancels
  isSuccessUpdateStatus: boolean
  isUpdateStatusLoading: boolean
}

@Module({ namespaced: true, name: 'cancel-validation', store, dynamic: true })
class CancelValidatonController extends VuexModule
  implements CancelValidationState {
  public presenter: CancelValidationPresenter = container.resolve(
    CancelValidationPresenter
  )
  public isLoading = false
  public cancelListData: Cancel[] = []
  public paginationData: Pagination = new Pagination()
  public cancelSummaryData: Summary = new Summary()
  public courierShipmentCancelListData: CourierShipmentCancels = {}
  public cancelDetailData: CancelDetail = new CancelDetail()
  public isSuccessUpdateStatus = false
  public isUpdateStatusLoading = false

  @Action({ rawError: true })
  public getCancelList(params: ICancelListParams): void {
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.setLoading(true)
    this.presenter
      .getCancelList(formattedParams)
      .then(res => {
        this.setCancelData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Pickup Location List Error',
          text: [400, 422].includes(error?.status)
            ? error?.error?.message?.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getCourierShipmentCancelList(payload: {
    params: ICancelListParams
    courierId: string
  }): void {
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(payload.params),
      'snake_case'
    )

    this.setLoading(true)
    this.presenter
      .getCourierShipmentCancelList(payload.courierId, formattedParams)
      .then(res => {
        this.setCourierShipmentCancelData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Pickup Location List Error',
          text: [400, 422].includes(error?.status)
            ? error?.error?.message?.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getCancelDetail(payload: {
    historyId: string
    params: ICancelValdationDetailParameters
  }): void {
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(payload.params),
      'snake_case'
    )

    this.setLoading(true)
    this.presenter
      .getCancelDetail(payload.historyId, formattedParams)
      .then(res => {
        this.setCancelDetailData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Cancel Detail Error',
          text: [400, 422].includes(error?.status)
            ? error?.error?.message?.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateStatusValidation(params: {
    historyId: string
    status: number
    description: string
  }): void {
    const payload = new UpdateStatusValidationRequest(
      params.status,
      params.description
    )

    this.setIsUpdateStatusLoading(true)
    this.presenter
      .updateStatusValidation(params.historyId, payload)
      .then(res => {
        this.setIsSuccessUpdateStatus(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Status Validation Error',
          text: [400, 422].includes(error?.status)
            ? error?.error?.message?.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsUpdateStatusLoading(false)
      })
  }

  @Action({ rawError: true })
  public exportCancelList(payload: Record<string, string | number>): void {
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(payload),
      'snake_case'
    )

    this.setLoading(true)

    this.presenter
      .exportCancelList(formattedParams)
      .then(url => {
        window.open(url)
      })
      .catch(error => {
        Vue.notify({
          title: 'Export Cancel Validation List Error',
          text: [400, 422].includes(error?.status)
            ? error?.error?.message?.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public exportCourierShipmentCancelList(payload: {
    courierId: number
    params: Record<string, string | number>
  }): void {
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(payload.params),
      'snake_case'
    )

    this.setLoading(true)

    this.presenter
      .exportCourierShipmentCancelList(payload.courierId, formattedParams)
      .then(url => {
        window.open(url)
      })
      .catch(error => {
        Vue.notify({
          title: 'Export Courier Shipment Cancel Validation List Error',
          text: [400, 422].includes(error?.status)
            ? error?.error?.message?.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setLoading(payload: boolean): void {
    this.isLoading = payload
  }

  @Mutation
  private setCancelData(payload: Cancels): void {
    this.cancelListData = <Cancel[]>payload.data
    this.paginationData = <Pagination>payload.pagination
    this.cancelSummaryData = <Summary>payload.summary
  }

  @Mutation
  private setCourierShipmentCancelData(payload: CourierShipmentCancels): void {
    this.courierShipmentCancelListData = payload
  }

  @Mutation
  private setCancelDetailData(payload: CancelDetail): void {
    this.cancelDetailData = payload
  }

  @Mutation
  private setIsUpdateStatusLoading(payload: boolean): void {
    this.isUpdateStatusLoading = payload
  }

  @Mutation
  public setIsSuccessUpdateStatus(payload: boolean): void {
    this.isSuccessUpdateStatus = payload
  }
}
export default getModule(CancelValidatonController)
